<template>
  <div class="role-post" v-loading="loading">
    <el-form ref="postForm" label-position="right" label-width="100px" :model="postData" :rules="rules">
      <el-form-item prop="roleName" label="角色名称">
        <el-input v-model="postData.roleName"></el-input>
      </el-form-item>
    </el-form>

    <!-- 栏目开关部分 -->
    <div class="navigation-flags">
      <h2>管理端权限</h2>
      <div class="flag-items home-flag">
        <div class="title">
          <div class="flag-title">概览</div>
          <div>
            是否可见
            <el-switch v-model="homeFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </div>
        </div>
      </div>

      <div class="flag-items student-flag">
        <div class="title">
          <div class="flag-title">学员列表</div>
          <div>
            是否可见
            <el-switch
              v-model="studentFlag"
              @change="studentFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="studentCheckList">
                  <el-checkbox label="20001" :disabled="!studentFlag">导出考生信息</el-checkbox>
                  <el-checkbox label="20003" :disabled="!studentFlag">查看</el-checkbox>
                  <el-checkbox label="20004" :disabled="!studentFlag">服务查看</el-checkbox>
                  <el-checkbox label="20006" :disabled="!studentFlag">强制完结</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!--  -->
      <div class="flag-items policy-flag">
        <div class="title">
          <div class="flag-title">服务审核</div>
          <div>
            是否可见
            <el-switch
              v-model="policyFlag"
              @change="policyFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="policyCheckList">
                  <el-checkbox label="30001" :disabled="!policyFlag">取消审核</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <!--  -->
      <div class="flag-items compensation-flag">
        <div class="title">
          <div class="flag-title">补偿审核</div>
          <div>
            是否可见
            <el-switch
              v-model="compensationFlag"
              @change="compensationFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="compensationCheckList">
                  <el-checkbox label="40001" :disabled="!compensationFlag">手动审核</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <!--  -->
      <div class="flag-items payment-flag">
        <div class="title">
          <div class="flag-title">确认打款</div>
          <div>
            是否可见
            <el-switch
              v-model="paymentFlag"
              @change="paymentFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="paymentCheckList">
                  <el-checkbox label="110001" :disabled="!paymentFlag">查看详情</el-checkbox>
                  <el-checkbox label="110002" :disabled="!paymentFlag">打款确认</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flag-items drvSchool-flag">
        <div class="title">
          <div class="flag-title">驾校管理</div>
          <div>
            是否可见
            <el-switch
              v-model="drvSchoolFlag"
              @change="drvSchoolFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="drvSchoolCheckList">
                  <el-checkbox label="50001" :disabled="!drvSchoolFlag">创建账号</el-checkbox>
                  <el-checkbox label="50002" :disabled="!drvSchoolFlag">编辑</el-checkbox>
                  <el-checkbox label="50003" :disabled="!drvSchoolFlag">设置密码</el-checkbox>
                  <el-checkbox label="50004" :disabled="!drvSchoolFlag">会员服务设置</el-checkbox>
                  <el-checkbox label="50005" :disabled="!drvSchoolFlag">充值</el-checkbox>
                  <el-checkbox label="50006" :disabled="!drvSchoolFlag">停用</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flag-items recharge-flag">
        <div class="title">
          <div class="flag-title">驾校余额明细</div>
          <div>
            是否可见
            <el-switch
              v-model="rechargeFlag"
              @change="rechargeFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="rechargeCheckList">
                  <el-checkbox label="60001" :disabled="!rechargeFlag">余额明细</el-checkbox>
                  <el-checkbox label="60002" :disabled="!rechargeFlag">导出数据</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flag-items template-flag">
        <div class="title">
          <div class="flag-title">费用设置模板</div>
          <div>
            是否可见
            <el-switch
              v-model="templateFlag"
              @change="templateFlagChangeHandler"
              active-color="#13ce66"
              inactive-color="#ff4949"></el-switch>
          </div>
        </div>
        <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="templateCheckList">
                  <el-checkbox label="70001" :disabled="!templateFlag">创建模板</el-checkbox>
                  <el-checkbox label="70002" :disabled="!templateFlag">查看</el-checkbox>
                  <el-checkbox label="70003" :disabled="!templateFlag">删除</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="flag-items enroll-flag">
        <div class="title">
          <div class="flag-title">报名统计</div>
          <div>
            是否可见
            <el-switch v-model="enrollFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            <!-- @change="enrollFlagChangeHandler" -->
          </div>
        </div>
        <!-- <div class="table">
          <table border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td class="table-title-td">权限类型</td>
            </tr>
            <tr>
              <td>
                <el-checkbox-group v-model="enrollCheckList">
                  <el-checkbox label="120000" :disabled="!enrollFlag">创建模板</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
          </table>
        </div> -->
      </div>

      <div class="flag-items home-flag">
        <div class="title">
          <div class="flag-title">操作日志</div>
          <div>
            是否可见
            <el-switch v-model="logFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-dialog-btns">
      <el-button type="primary" @click="submitHandler" :loading="loading">确认</el-button>
      <el-button
        @click="
          () => {
            this.$emit('end');
          }
        "
        >取消
      </el-button>
    </div>
  </div>
</template>

<script>
import {sysRoleAdd, sysRoleUpdate, sysPermissionGetList} from '@/api/index';
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      permissionDataList: [],
      loading: false,
      homeFlag: true,
      //
      studentFlag: true,
      studentCheckList: [],
      //
      policyFlag: true,
      policyCheckList: [],
      //
      compensationFlag: true,
      compensationCheckList: [],
      //
      paymentFlag: true,
      paymentCheckList: [],
      //
      drvSchoolFlag: true,
      drvSchoolCheckList: [],
      //
      rechargeFlag: true,
      rechargeCheckList: [],
      //
      templateFlag: true,
      templateCheckList: [],
      //
      enrollFlag: true,
      //
      logFlag: true,
      postData: {
        roleName: '',
        permissionIdList: [],
      },
      rules: {
        roleName: [this.$rules.required],
      },
    };
  },
  created() {
    if (this.$props.row) {
      this.homeFlag = false;
      this.studentFlag = false;
      this.policyFlag = false;
      this.compensationFlag = false;
      this.paymentFlag = false;
      this.drvSchoolFlag = false;
      this.rechargeFlag = false;
      this.templateFlag = false;
      this.enrollFlag = false;
      this.logFlag = false;

      this.postData.roleName = this.$props.row.roleName;
      this.$props.row.sysPermissionList.forEach((item) => {
        item.permissionId += '';
        if (item.permissionId == 1) {
          this.homeFlag = true;
        } else if (item.permissionId.indexOf('2000') != -1) {
          this.studentFlag = true;
          if (item.permissionId == '20000') {
            return true;
          } else {
            this.studentCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('3000') != -1) {
          this.policyFlag = true;
          if (item.permissionId == '30000') {
            return true;
          } else {
            this.policyCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('4000') != -1) {
          this.compensationFlag = true;
          if (item.permissionId == '40000') {
            return true;
          } else {
            this.compensationCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('11000') != -1) {
          this.paymentFlag = true;
          if (item.permissionId == '110000') {
            return true;
          } else {
            this.paymentCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('5000') != -1) {
          this.drvSchoolFlag = true;
          if (item.permissionId == '50000') {
            return true;
          } else {
            this.drvSchoolCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('6000') != -1) {
          this.rechargeFlag = true;
          if (item.permissionId == '60000') {
            return true;
          } else {
            this.rechargeCheckList.push(item.permissionId);
          }
        } else if (item.permissionId.indexOf('7000') != -1) {
          this.templateFlag = true;
          if (item.permissionId == '70000') {
            return true;
          } else {
            this.templateCheckList.push(item.permissionId);
          }
        } else if (item.permissionId == 120000) {
          this.enrollFlag = true;
        } else if (item.permissionId == 100000) {
          this.logFlag = true;
        }
      });
    }
  },
  methods: {
    studentFlagChangeHandler() {
      if (!this.studentFlag) {
        this.studentCheckList = [];
      }
    },

    policyFlagChangeHandler() {
      if (!this.policyFlag) {
        this.policyCheckList = [];
      }
    },

    compensationFlagChangeHandler() {
      if (!this.compensationFlag) {
        this.compensationCheckList = [];
      }
    },

    paymentFlagChangeHandler() {
      if (!this.paymentFlag) {
        this.paymentCheckList = [];
      }
    },

    drvSchoolFlagChangeHandler() {
      if (!this.drvSchoolFlag) {
        this.drvSchoolCheckList = [];
      }
    },

    rechargeFlagChangeHandler() {
      if (!this.rechargeFlag) {
        this.rechargeCheckList = [];
      }
    },

    templateFlagChangeHandler() {
      if (!this.templateFlag) {
        this.templateCheckList = [];
      }
    },

    getPermissionList() {
      sysPermissionGetList().then((res) => {
        if (res.data.code == 0) {
          this.permissionDataList = res.data.data;
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },

    submitHandler() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postData = {
            roleName: this.postData.roleName,
          };
          if (this.$props.row) {
            postData['roleId'] = this.$props.row.roleId;
          }
          // 处理权限id  postData.permissionIdList [{permissionId, visibleStatus}]
          postData['permissionIdList'] = [];
          if (this.homeFlag) {
            postData.permissionIdList.push({
              permissionId: '1',
              visibleStatus: 2,
            });
          }
          if (this.studentFlag) {
            if (this.studentCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '20000',
                visibleStatus: 2,
              });
              this.studentCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '20000',
                visibleStatus: 2,
              });
            }
          }
          if (this.policyFlag) {
            if (this.policyCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '30000',
                visibleStatus: 2,
              });
              this.policyCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '30000',
                visibleStatus: 2,
              });
            }
          }
          if (this.compensationFlag) {
            if (this.compensationCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '40000',
                visibleStatus: 2,
              });
              this.compensationCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '40000',
                visibleStatus: 2,
              });
            }
          }
          if (this.paymentFlag) {
            if (this.paymentCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '110000',
                visibleStatus: 2,
              });
              this.paymentCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '110000',
                visibleStatus: 2,
              });
            }
          }
          if (this.drvSchoolFlag) {
            if (this.drvSchoolCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '50000',
                visibleStatus: 2,
              });
              this.drvSchoolCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '50000',
                visibleStatus: 2,
              });
            }
          }
          if (this.rechargeFlag) {
            if (this.rechargeCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '60000',
                visibleStatus: 2,
              });
              this.rechargeCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '60000',
                visibleStatus: 2,
              });
            }
          }
          if (this.templateFlag) {
            if (this.templateCheckList.length) {
              postData.permissionIdList.push({
                permissionId: '70000',
                visibleStatus: 2,
              });
              this.templateCheckList.forEach((item) => {
                postData.permissionIdList.push({
                  permissionId: item,
                  visibleStatus: 2,
                });
              });
            } else {
              postData.permissionIdList.push({
                permissionId: '70000',
                visibleStatus: 2,
              });
            }
          }
          if (this.enrollFlag) {
            postData.permissionIdList.push({
              permissionId: '120000',
              visibleStatus: 2,
            });
          }
          if (this.logFlag) {
            postData.permissionIdList.push({
              permissionId: '100000',
              visibleStatus: 2,
            });
          }

          let fn = this.$props.row ? sysRoleUpdate : sysRoleAdd;
          fn(postData).then((res) => {
            this.loading = false;
            if (res.data.code == 0) {
              this.$message.success(res.data.msg);
              this.$emit('success');
              this.$emit('end');
            } else {
              this.$message.error(res.data.msg);
              return false;
            }
          });
        } else {
          this.loading = false;
          this.$message.warning('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.role-post {
  .navigation-flags {
    height: 600px;
    overflow-y: scroll;
    padding-bottom: 15px;
    .flag-items {
      box-sizing: border-box;
      padding: 0 10px;
      margin-bottom: 10px;
      .title {
        display: flex;
        justify-content: space-between;
        div {
          font-size: 16px;
          font-weight: 500;
        }
        .flag-title {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .table {
        margin: 5px 0 0 0;
        table {
          border-radius: 8px;
          overflow: hidden;
          width: 100%;
        }
        td {
          padding: 6px;
          line-height: 25px;
        }
        .table-title-td {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
